import React from "react";
import Header from "./base/Header";
import Footer from "./base/Footer";
import HeroSection from "./base/HeroSection";
import Section2 from "./base/Section2";
import { Helmet } from "react-helmet";
function Homepage() {
    return (
        <>
  <Helmet>
    <title>Homepage - รับทำเว็บไซต์และพัฒนาเว็บไซต์</title>
    <meta
        name="description"
        content="รับทำเว็บไซต์และพัฒนาเว็บไซต์ บริการสร้างเว็บไซต์สำหรับธุรกิจทุกประเภท พร้อมระบบที่รองรับ SEO และการออกแบบที่สวยงาม."
    />
    <meta
        name="keywords"
        content="รับทำเว็บไซต์, พัฒนาเว็บไซต์, สร้างเว็บไซต์, บริการเว็บไซต์, เว็บไซต์ธุรกิจ, ออกแบบเว็บไซต์"
    />
    <meta property="og:title" content="Homepage - รับทำเว็บไซต์และพัฒนาเว็บไซต์" />
    <meta
        property="og:description"
        content="บริการรับทำเว็บไซต์ สร้างเว็บไซต์สำหรับธุรกิจ รองรับ SEO และออกแบบที่ตอบโจทย์ความต้องการของคุณ."
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://t2etech.com" />
    <meta property="og:image" content="https://t2etech.com/thumbnail.jpg" />
</Helmet>

        <HeroSection/>
        <Section2/>
        {/* <AchievementsSection/>
        <CardDetail/>
        <Skills/>
        <ProjectsSection/>
        <PricingTable/>
        <CustomerReview/> */}
        </>

    );
  }
  
  export default Homepage;
  