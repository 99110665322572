import React from "react";
import { motion } from "framer-motion";
import { TypeAnimation } from "react-type-animation";
import ASTRO1 from "../../images/astronaut_wave.gif";
import "../base/custom.css"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const HeroSection = () => {
    return (
      <>
      <Helmet>
          <title>ASTRO - แอปพลิเคชั่น</title>
          <meta name="description" content="ASTRO - แอปพลิเคชั่น" />
          <meta name="keywords" content="ASTRO - แอปพลิเคชั่น" />
      </Helmet>
      <section className="lg:py-16 mt-24" id="about">
        <div className="grid grid-cols-1 sm:grid-cols-12">
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="col-span-8 place-self-center text-center sm:text-left sm:justify-self-center lg:justify-self-start"
          >
           <h1 className=" mb-4 text-3xl sm:text-5xl lg:text-5xl font-extrabold font-english text-transparent bg-clip-text bg-gradient-to-r from-secondary1 to-secondary2">
           WELCOME TO
           ASTRO<br /> 
            </h1>
            <h1 className="text-white mb-4 text-3xl sm:text-5xl lg:text-5xl lg:leading-normal font-extrabold font-thai">
            
            </h1>
            <p className="text-white text-base sm:text-lg mb-10 lg:text-4xl font-extrabold  font-thai" >
            Explore Beyond, Connect Within.
            </p>
            <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=785dnfpq" 
              target="_blank" 
              className="inline-block bg-secondary5 text-white font-bold py-4 px-6 mt-5 rounded-full transform transition-transform duration-300 hover:translate-y-2">
              DOWNLOAD NOW
            </a>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0 }}
            className="col-span-4 place-self-center mt-4 lg:mt-0 transition duration-500"
          >
            <div className="rounded-full  w-[250px] h-[250px] lg:w-[350px] lg:h-[350px] mt-5 mb-5 lg:mt-0 lg:mb-0 relative">
              <div className="absolute rounded-full  w-[350px] h-[350px] blur-[150px] z-1  lg:bg-gradient-to-tr lg:from-secondary5/50 lg:to-secondary6/50 ">

              </div>
                
              <img
                src={ASTRO1}
                alt="hero image"
                className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-full h-full "
              />
            </div>
          </motion.div>
        </div>
      </section>
      </>
    );
  };

  export default HeroSection;
  