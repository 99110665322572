import React, { useState } from "react";
import NavLink from "./NavLink";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import MenuOverlay from "./MenuOverlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import Logo from "../../images/Logo_Color1_Transparent.PNG";
const navLinks = [
  {
    title: "About",
    path: "about",
  },
  {
    title: "Policy",
    path: "Policy",
    submenu: [
      {
        title: "Privacy Policy",
        description: "See how Astro is committed to protecting your privacy.",
        path: "/privacy-policy",
      },
      {
        title: "Terms and Conditions",
        description: "Find answers and solutions to popular questions.",
        path: "/help-center",
      },
    ],
  },
  {
    title: "Review",
    path: "review",
  },
  {
    title: "Plan",
    path: "plan",
  },
  {
    title: "Service",
    path: "services",
  },
];

function Header() {
  const [activeMenu, setActiveMenu] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleMouseEnter = (index) => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout); // Clear timeout if mouse re-enters quickly
    }
    setActiveMenu(index);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setActiveMenu(null); // Close menu after delay
    }, 200); // Delay for 200ms
    setHoverTimeout(timeout);
  };

  const handleSubmenuEnter = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout); // Prevent closing when submenu is hovered
    }
  };

  const handleSubmenuLeave = () => {
    setActiveMenu(null); // Close menu when leaving submenu
  };

  return (
    <nav className="fixed mx-auto top-0 left-0 right-0 z-10 bg-primary3/50 bg-opacity-100 font-english">
      <div className="flex container lg:py-4 flex-wrap items-center justify-between mx-auto px-4 py-2">
        {/* Mobile Menu Button */}
  <div className="mobile-menu block md:hidden">
          {!navbarOpen ? (
            <button
              onClick={() => setNavbarOpen(true)}
              className="flex items-center px-3 py-2 border rounded border-slate-200 text-slate-200 hover:text-white hover:border-white"
            >
              <Bars3Icon className="h-5 w-5" />
            </button>
          ) : (
            <button
              onClick={() => setNavbarOpen(false)}
              className="flex items-center px-3 py-2 border rounded border-slate-200 text-slate-200 hover:text-white hover:border-white"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          )}
        </div>

        {/* Desktop Menu */}
        <div className="flex row items-center justify-center">
        <img src={Logo} width={50} height={50} className="mr-10"/>
        <div className="menu hidden md:block md:w-auto">
          <ul className="flex flex-col space-y-4 mt-16 md:mt-0 md:flex-row md:space-y-0 md:space-x-8">
            {navLinks.map((link, index) => (
              <li
                key={index}
                className="relative group"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="flex items-center cursor-pointer">
                  <NavLink
                    to={link.path}
                    title={link.title}
                    className="block px-4 py-2 text-gray-300 hover:text-white"
                  />
                  {link.submenu && (
                    <span className="ml-2 transition-transform duration-500">
  <ChevronDownIcon
        className={`h-4 w-4 text-gray-300 group-hover:text-white ${
          activeMenu === index ? "rotate-180" : "rotate-0"
        }`}
  />
                    </span>
                  )}
                </div>

                {/* Submenu Dropdown */}
                {link.submenu && activeMenu === index && (
                  <div
                    className="absolute top-full left-0 min-w-[240px] bg-white shadow-lg rounded-lg mt-2 transition-opacity duration-200"
                    onMouseEnter={handleSubmenuEnter}
                    onMouseLeave={handleSubmenuLeave}
                  >
                    {link.submenu.map((item, i) => (
                      <a
                        key={i}
                        href={item.path}
                        className="block p-4 hover:bg-gray-100 rounded-lg"
                      >
                        <h4 className="font-bold text-primary">{item.title}</h4>
                        <p className="text-sm text-gray-600">
                          {item.description}
                        </p>
                      </a>
                    ))}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        </div>

        {/* Social Media Icons */}
        <div className="flex items-center justify-center row">
          <a
            href=" https://www.instagram.com/astroscopes_official?igsh=MWh4c2Jza29rNmdqaA=="
            target="_blank"
            rel="noopener noreferrer"
            className="font-thai mx-2"
          >
            <FontAwesomeIcon icon={faInstagram} size="xl" color="white" />
          </a>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="font-thai mx-2"
          >
            <FontAwesomeIcon icon={faFacebook} size="lg" color="white" />
          </a>
          <a
            href="https://www.tiktok.com/@astroscpoes_official?_t=ZS-8rfQ4s9BaOf&_r=1"
            target="_blank"
            rel="noopener noreferrer"
            className="font-thai mx-2"
          >
            <FontAwesomeIcon icon={faTiktok} size="lg" color="white" />
          </a>
        </div>
      </div>
      {navbarOpen ? <MenuOverlay links={navLinks} /> : null}
    </nav>
  );
}

export default Header;


