import React, { useState } from 'react'
import NavLink from './NavLink'
import { motion } from 'framer-motion'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import About1 from '../../images/about.png'
import About2 from '../../images/home1.png'
import MenuOverlay from './MenuOverlay'
import { Link } from 'react-router-dom'

function Section2() {
  return (
    <>
    <div className='mb-24'>
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 items-center gap-12 mt-24">
        {/* Left Section */}
        <motion.div
          className="flex justify-center lg:ml-10 lg:justify-start"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <img
            src={About2}
            alt="Jagat mobile app"
            className="max-w-sm"
          />
        </motion.div>
        
        <div className="space-y-6 lg:mr-5">
          <h1 className="text-4xl font-bold text-white">
            <span className="text-gradient">Stay Connected!</span>
          </h1>
          <p className="text-xl text-gray-400">
          Stay connected like never before with Astro! Share real-
          time updates, see where your friends are, and easily plan
          spontaneous meetups. Discover new places, connect with
          your circle, and make every moment count.
          </p>
        </div>

        {/* Right Section (Image + Animation) */}
      </div>




    
      </div>
    </>
  );
}

export default Section2;
